.postagem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 10px;
}
.plus {
  margin-left: 10px;
}
.foto-postagem {
  margin-right: 2px;
  display: flex;
  flex-direction: column;
}
.foto-postagem img {
  width: 100% !important;
  border-radius: 4px;
  margin: 3px 0;
}
.card {
  min-width: 100%;
}
.tabela {
  width: 100%; /* A tabela ocupa 100% da largura do contêiner */
  table-layout: fixed; /* As colunas mantêm uma largura fixa */
}

.tabela th,
.tabela td {
  padding: 10px; /* Adiciona espaço ao redor do conteúdo */
}

.col-titulo {
  width: 25%; /* Define a largura para a coluna "Título" */
}

.col-subtitulo {
  width: 25%; /* Define a largura para a coluna "Subtítulo" */
}

.col-texto {
  width: 40%; /* Define a largura para a coluna "Texto" */
}

.col-acoes {
  width: 10%; /* Define a largura para a coluna "Ações" */
}
