/* FadeInCard.css */
.fade-in-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  .fade-in-card {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s, transform 1s;
  }
  
  .fade-in-card.visible {
    opacity: 1;
    transform: translateY(0);
  }
  .title h1 {
    margin-left: 50px;
    font-size: 26px;
  }
  