.separator-bar {
  width: 100%; /* Largura total */
  height: 2px !important; /* Altura de 2px */
  background: linear-gradient(
    to right,
    red,
    green,
    yellow,
    blue,
    orange,
    pink
  ); /* Gradiente com as cores especificadas */
  margin: 20px 0; /* Margem acima e abaixo da barra */
}
