
.title {
    width: 100%;
    margin: 15px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    color: #555;
}
.container-fluid {
    padding: 10px;
}
.scrollable-modal {
    max-height: calc(100vh - 100px); /* Defina a altura máxima desejada */
    overflow-y: auto;
}
.tabela { 
  font-size: 12px;
}
.botoes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.botao {
  margin-left: 0px;
}
.tabela {
  width: 100%;
  table-layout: fixed;
}
.col1 {
  width: 7% !important;
}
.col2 {
  width: 13% !important;
}
.col3 {
  width: 20% !important;
}
.col4 {
  width: 45% !important;
}
.col5 {
  width: 8% !important;
}
.col6 {
  width: 7% !important;
}