.chart-stat-container { 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.chart-stat-title {
  margin-bottom: 10px; /* Ajuste o espaço inferior do título */
  font-size: 16px; /* Ajuste o tamanho do título, se necessário */
  text-align: center; /* Centraliza o título */
}
.chart-content-stats {
  width: 300px;  /* Largura fixa */
  height: 300px; /* Altura fixa */
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-content-stats canvas {
  max-width: 80%;
  max-height: 80%;
}