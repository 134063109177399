.container-fluid {
  margin-top: -10PX;
}
h3 {
  margin: 20px 0 0 20px;
  font-size: 20px;
  color: #AB21B5;
}
span {
  margin-left: 25px;
  margin-bottom: 0;
  font-size: 12px;
  color: #000;
}
.content {
  background-color: #fff !important;
  box-shadow: 2px 2px 2px 3px rgba(75, 74, 74, 0.2);
  margin-bottom: 0px;
}
.bem-vindo {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.bem-vindo-p {
  margin-top: 10px;
  width: 90%;
  margin-left: 5%;
  font-size: 12px;
}
.card {
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.carousel-h3 {
  margin-top: 20px !important;
}
.carousel-h3 span {
  color: red;
}