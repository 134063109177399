.text-icon-tv {
  padding-left: 0px !important;
  margin-left: -25px !important;
  display: flex;
  justify-content: start;
  
}
.planoTv-img img{
  width: 60px !important;
}
.form-label {
  /* margin-left: 25px; */
}
.img-preview img {
  width: 200px !important;
}
.phone-img img {
  width: 100px !important;
}
