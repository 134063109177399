
.title {
    width: 100%;
    margin: 15px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    color: #555;
}
.container-fluid {
    padding: 10px;
}
.scrollable-modal {
    max-height: calc(100vh - 100px); /* Defina a altura máxima desejada */
    overflow-y: auto;
}
.card {
    padding: 20px;
    margin-bottom: 20px;
}
.card img{
    width: 100%;    
}
.card-title {
    border-bottom: 1px solid #ccc;
}
.card-row {
   display: flex;
   flex-direction: row; 
}
.card-row img {
    width: 100%;
}
.foto-preview {
    width: 100%;
}
.foto-preview img {
    width: 100%;
}
.carrosel-button button {
    margin-right: 10px !important;
}

/*
style form
*/
.input-group-text {
    width: 40px;
    border-radius: 5px 0px 0px 5px !important;
    background-color: #E9ECEF;
  }
  .input-group-text-area {
    width: 40px;
    height: 123px !important;
    border-radius: 5px 0px 0px 5px !important;
    background-color: #E9ECEF;
    border: 1px solid #CED4DA;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .text-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 5px;
    width: 100%;
  }
  
  .text-icon img {
    width: 13px;
  }
  .text-icon input {
    border-radius: 0px 5px 5px 0px;
  }
  
  .text-icon textarea {
    border-radius: 0px 5px 5px 0px;
  }
  .card-img {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .foto-preview {
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
  }

  .foto-preview img {
    margin-top: 10px;
    width: 100%;
    border-radius: 5px;
  }
  
  label {
    font-size: 12px;
    position: relative;
    bottom: -3px;
  }
  /* Adicione estilos de transição de opacidade para fadeIn */
.fade-in-enter {
  opacity: 0;
}

.fade-in-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fade-in-exit {
  opacity: 1;
}

.fade-in-exit-active {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
