.promotion-table {
  font-size: 11px;
}
.promotion-img {
  width: 10%;
}

.promotion-img img {
  width: 50px;
}

.promotion-titulo {
  width: 20%;

}

.promotion-sub {
  width: 30%;
}

.promotion-text {
  width: 40%;
}

.promotion-actions {}