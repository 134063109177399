.chart-visit-container {
  margin-bottom: 20px; /* Ajuste o espaço inferior entre o título e o gráfico */
}

.chart-visit-title {
  margin-bottom: 10px; /* Ajuste o espaço inferior do título */
  font-size: 18px; /* Ajuste o tamanho do título, se necessário */
  text-align: center; /* Centraliza o título */
}
.chart-visit-title-bar {
  margin-bottom: 10px;
  font-size: 18px; /* Ajuste o tamanho do título, se necessário */
  text-align: center; /* Centraliza o título */
}
