/* FadeInCard.css */
.fade-in-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  .fade-in-card {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s, transform 1s;
  }
  
  .fade-in-card.visible {
    opacity: 1;
    transform: translateY(0);
  }
  .card-phone {
    padding: 20px 0 0 50px;
  }
  .card-phone h3 {
    margin: 20px 0 0 20px;
    font-size: 20px;
    color: #AB21B5;
  }
  .card-phone span{
    color: red;
  }
  