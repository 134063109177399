
.title {
    width: 100%;
    margin: 15px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    color: #555;
}
.container-fluid {
    padding: 10px;
}
.scrollable-modal {
    max-height: calc(100vh - 100px); /* Defina a altura máxima desejada */
    overflow-y: auto;
}
.offer {
    padding: 20px;
    margin-bottom: 20px;
}
.offer img{
    width: 100%;    
}
.offer-title {
  border-bottom: 1px solid #ccc;
  padding: 10px;
}
.offer-title h3 {
  font-size: 14px;
}
.offer-row {
   display: flex;
   flex-direction: row; 
}
.offer-row img {
    width: 100%;
}
.foto-preview {
    width: 100%;
}
.foto-preview img {
    width: 100%;
}
.offer-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.offer-button button {
    margin: 5px;
    font-size: 10px;
    width: 50%;
}
.table-container {
  width: 100%;
  border-collapse: collapse;
}
.table-container th,
.table-container td {
  padding: 8px;
  /* border: 1px solid #ddd; */
  text-align: left;
}
.table-container th:last-child,
.table-container td:last-child {
  text-align: right;
}
.table-container tr {
  border-bottom: 1px solid #e6e5e5;
  padding: 2px;
}

/*
style form
*/
.input-group-text {
    width: 40px;
    border-radius: 5px 0px 0px 5px !important;
    background-color: #E9ECEF;
  }
  .input-group-text-area {
    width: 40px;
    height: 123px !important;
    border-radius: 5px 0px 0px 5px !important;
    background-color: #E9ECEF;
    border: 1px solid #CED4DA;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .text-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 5px;
    width: 100%;
  }
  
  .text-icon img {
    width: 13px;
  }
  .text-icon input {
    border-radius: 0px 5px 5px 0px;
  }
  
  .text-icon textarea {
    border-radius: 0px 5px 5px 0px;
  }
  .offer-img {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .foto-preview {
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
  }

  .foto-preview img {
    margin-top: 10px;
    width: 100%;
    border-radius: 5px;
  }
  
  label {
    font-size: 12px;
    position: relative;
    bottom: -3px;
  }