.card-rural {
  padding: 20px 0 0 50px;
}
.card-rural h3 {
  margin: 20px 0 0 20px;
  font-size: 20px;
  color: #AB21B5;
}
.card-rural span{
  color: red;
}