.col111 {
  width: 5%;
}
.col222 {
  width: 15%;
}
.col333 {
  width: 20%;
}
.col444 {
  width: 10%;
}
.col555 {
  width: 10%;
}
.col666 {
  width: 35%;
}
.col777 {
  width: 5%;
}
.tabelas {
}
.cidade-img img {
  width: 100px;
}