
.title {
  width: 100%;
  margin: 15px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  color: #555;
  font-size: 16px !important;
}
.imagem-th {
  width: 120px !important;
}
.container-fluid {
  padding: 10px;
}
.scrollable-modal {
  max-height: calc(100vh - 100px); /* Defina a altura máxima desejada */
  overflow-y: auto;
}
.offer {
  padding: 20px;
  margin-bottom: 20px;
}
.offer img{
  width: 50px;    
}
.offer-title {
border-bottom: 1px solid #ccc;
padding: 10px;
}
.offer-title p {
  font-size: 12px;
}
.offer-row {
 display: flex;
 flex-direction: row; 
}
.offer-row img {
  width: 100%;
}
.foto-preview {
  width: 100%;
}
.foto-preview img {
  width: 50%;
}
.offer-button button {
  margin-right: 10px;
}
.imagem img {
  width: 50px;
  margin: 2px;
}
.table-container {
  font-size: 12px;
}
.tabela {
  width: 100%; 
}
.coluna1 {
  width: 10%;
}
.coluna2 {
  width: 10%;
}
.coluna3 {
  width: 25%;
}
.coluna4 {
  width: 45%;
}
.coluna5 {
  width: 10%;
  text-align: right;
}
tr {
  border-bottom: 1px solid #e6e5e5;
  padding: 2px;
}
/*
style form
*/
.input-group-text {
  width: 40px;
  height: 38px;
  border-radius: 5px 0px 0px 5px !important;
  background-color: #E9ECEF;
}
.input-group-text-area {
  width: 40px;
  height: 123px !important;
  border-radius: 5px 0px 0px 5px !important;
  background-color: #E9ECEF;
  border: 1px solid #CED4DA;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5px;
  width: 100%;
}

.text-icon img {
  width: 13px;
}
.text-icon input {
  border-radius: 0px 5px 5px 0px;
}

.text-icon textarea {
  border-radius: 0px 5px 5px 0px;
}
.offer-img {
  border: none;
}
.offer-img img{
  width: 100px !important;
  padding: 5px;
}

.foto-preview {
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.foto-preview img {
  margin-top: 10px;
  width: 100%;
  border-radius: 5px;
}

label {
  font-size: 12px;
  position: relative;
  bottom: -3px;
}
.botoes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.botao {
  margin-left: 5px;
}