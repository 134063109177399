.carousel-h3 h3 {
  color: #99009A;
  font-size: 20px;

}
.spinner-component {
  width: 100%;
  background-color: #181818;
}
.spinner-component img{
  width: 30%;
}
.bar {
  max-height: 56px; /* Limita a altura */
  overflow: hidden; /* Remove o scroll */
  white-space: nowrap; /* Impede quebra de linha */
}

.bar p {
  display: inline-block; /* Faz com que os parágrafos fiquem em linha */
  margin-right: 10px; /* Espaçamento entre os elementos */
  margin-top: 12px;
}
.logo-cms {
  margin-left: 10px;
}
.form-bar {
  margin-right: 10px !important;
}
.mr-sm-2 {
  margin-right: 5px;
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0 !important;
  padding-right: 20px;
}

.footer img {
  width: 100px;
  margin-left: 50px;
}