.label {
  font-size: 11px;
}
::placeholder {
  font-size: 11px; /* Ajuste o tamanho da fonte como preferir */
  color: #6c757d;  /* Opcional: cor do placeholder */
}
.icon-tv img {
  width: 80px;
}
.combo-container {
  display: flex;
  align-items: center;
}
