/* .login-bg {
  height: 100vh;
  padding: 0;
  background: no-repeat fixed center url("../../assets/images/website.png");
  background-position: center top;
  background-size: 100% auto;
} */
.login-bg {  
  background-image: linear-gradient(135deg, transparent 0%, transparent 21%,rgba(131, 20, 209,0.4) 21%, rgba(131, 20, 209,0.4) 51%,transparent 51%, transparent 100%),linear-gradient(45deg, transparent 0%, transparent 16%,rgb(131, 20, 209) 16%, rgb(131, 20, 209) 21%,rgb(88, 22, 184) 21%, rgb(88, 22, 184) 64%,transparent 64%, transparent 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255));
  /* background-image: linear-gradient(45deg, rgba(203, 78, 191,0.5) 0%, rgba(203, 78, 191,0.5) 12%,transparent 12%, transparent 20%,rgba(225, 118, 209,0.5) 20%, rgba(225, 118, 209,0.5) 24%,rgba(236, 138, 217,0.5) 24%, rgba(236, 138, 217,0.5) 35%,rgba(192, 58, 183,0.5) 35%, rgba(192, 58, 183,0.5) 36%,rgba(214, 98, 200,0.5) 36%, rgba(214, 98, 200,0.5) 100%),linear-gradient(135deg, rgb(203, 78, 191) 0%, rgb(203, 78, 191) 11%,transparent 11%, transparent 23%,transparent 23%, transparent 33%,rgb(236, 138, 217) 33%, rgb(236, 138, 217) 64%,rgb(192, 58, 183) 64%, rgb(192, 58, 183) 83%,rgb(214, 98, 200) 83%, rgb(214, 98, 200) 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255)); */
  /* Preenche a tela inteira */
  width: 100vw;
  height: 100vh;
  /* Garante que o background não se repita */
  background-repeat: no-repeat;
  /* Ajusta o tamanho do background */
  background-size: cover;
  /* Garante o alinhamento */
  background-position: center;
  /* Remove margens e padding do body */
  margin: 0;
  padding: 0;
}
.content-login {
  /* width: 70% !important; */
  padding: 80px;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
}
.card {
  box-shadow: 2px 2px 2px rgb(86, 86, 86);
}
.bots {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.bots p{
  font-weight: bold;
  margin: 10px;
  color: #666;
}
.logo-giga img {
  width: 200px !important;
}

.text-center {
  padding-top: 100px;
}
.cms-logo img {
  margin-top: -20px;
  width: 63px !important;
}

.cms-logo p {
  font-weight: bold;
  margin: 10px;
}

.wifiz img {
  width: 80%;
}

.separator {
  width: 100%;
}

.separator img {
  width: 100%;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}



.btn-hover {
  display: flex;  /* Usamos flexbox para centralizar */
  justify-content: center;  /* Centraliza o conteúdo horizontalmente */
  align-items: center;  /* Centraliza o conteúdo verticalmente */
  width: 101%;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  margin-top: 10px;
  margin-left: -3px;
  height: 40px;
  text-align: center;
  border: none;
  background-size: 300% 100%;

  border-radius: 5px;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.color-1 {
  background-image: linear-gradient(to right, #25aae1, #40e495, #30dd8a, #2bb673);
  box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
}

.btn-hover.color-2 {
  background-image: linear-gradient(to right, #f5ce62, #e43603, #fa7199, #e85a19);
  box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
}

.btn-hover.color-3 {
  background-image: linear-gradient(to right, #667eea, #764ba2, #6B8DD6, #8E37D7);
  box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
}

.btn-hover.color-4 {
  background-image: linear-gradient(to right, #fc6076, #ff9a44, #ef9d43, #e75516);
  box-shadow: 0 4px 15px 0 rgba(252, 104, 110, 0.75);
}

.btn-hover.color-5 {
  background-image: linear-gradient(to right, #0ba360, #3cba92, #30dd8a, #2bb673);
  box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
}

.btn-hover.color-6 {
  background-image: linear-gradient(to right, #009245, #FCEE21, #00A8C5, #D9E021);
  box-shadow: 0 4px 15px 0 rgba(83, 176, 57, 0.75);
}

.btn-hover.color-7 {
  background-image: linear-gradient(to right, #6253e1, #852D91, #A3A1FF, #EC268F);
  box-shadow: 0 4px 5px 0 rgba(126, 52, 161, 0.75);
}

.btn-hover.color-8 {
  background-image: linear-gradient(to right, #29323c, #485563, #2b5876, #4e4376);
  box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
}

.btn-hover.color-9 {
  background-image: linear-gradient(to right, #25aae1, #4481eb, #04befe, #3f86ed);
  box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
}

.btn-hover.color-10 {
  background-image: linear-gradient(to right, #ed6ea0, #ec8c69, #f7186a, #FBB03B);
  box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75);
}

.btn-hover.color-11 {
  background-image: linear-gradient(to right, #eb3941, #f15e64, #e14e53, #e2373f);
  box-shadow: 0 5px 15px rgba(242, 97, 103, .4);
}

@media (max-width: 768px) {
  .card {
    margin-top: 20px;
  }
}
