.chart-container {
  margin-bottom: 20px; /* Ajuste o espaço inferior entre o título e o gráfico */
}

.chart-title {
  margin-bottom: 10px; /* Ajuste o espaço inferior do título */
  font-size: 18px; /* Ajuste o tamanho do título, se necessário */
  text-align: center; /* Centraliza o título */
}
.content-stats {
  padding: 0 !important;
  margin: 0 !important;
}
.chatbot-tabela {
  margin-top: 25px ;
}
.chatbot-tabela table {
  font-size: 11px;
  margin-top: 30px ;
}
.acoes-table {
  width: 10%;
}